<template>
  <span>
    <v-combobox
      v-if="email"
      multiple
      v-model="emails"
      :items="items"
      :label="label || 'Add Label Here'"
      :placeholder="placeholder || ''"
      :delimiters="[',', ';', ' ']"
      :error="errEmail"
      :messages="errEmail ? 'Please enter valid email(s)' : null"
      append-icon
      chips
      tags
      deletable-chips
      hint="Paste emails using comma, semicolon, space, or newline as separators."
      small-chips
      filled
      @paste="handlePaste"
      @input="validate"
      counter="20"
      attach
    >
      <template #selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :color="!regexValidEmail.test(item) ? 'error' : ''"
          :input-value="selected"
          small
        >
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon
            small
            @click="parent?.selectItem(item)"
            :color="!regexValidEmail.test(item) ? 'white' : 'dark-grey'"
          >
            cancel
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <!-- <v-combobox
      v-if="apps"
      multiple
      v-model="allApps"
      :items="items"
      :label="label || 'Add Label Here'"
      :placeholder="placeholder || 'placeholder'"
      :delimiters="[',', ';', ' ']"
      append-icon
      chips
      deletable-chips
      small-chips
      filled
      @input="validateApps"
      counter="12"
    >
    </v-combobox> -->

    <v-select
      ref="select"
      v-if="apps"
      v-model="allApps"
      :items="items"
      :menu-props="{ maxHeight: '400', offsetY: true }"
      :label="label || 'Add Label Here'"
      :placeholder="placeholder || 'placeholder'"
      filled
      multiple
      autocomplete
      item-text="name"
      item-value="id"
      deletable-chips
      small-chips
      persistent-hint
      counter="12"
    >
      <!-- <template v-slot:selection="{ item }">
        <v-chip small>
          <span>{{ item.name }}</span>
        </v-chip>
      </template> -->
      <template slot="item" slot-scope="data">
        <span class="ml-5"> {{ data.item.name }} </span>
        <v-spacer />
        <PlatformIcon
          size="20"
          android
          color="primary"
          class="mr-4"
          v-if="
            data &&
            data.item &&
            data.item.platforms &&
            data.item.platforms.includes('android')
          "
        />
        <PlatformIcon
          size="20"
          apple
          color="primary"
          v-if="
            data &&
            data.item &&
            data.item.platforms &&
            data.item.platforms.includes('ios')
          "
        />
      </template>
    </v-select>
  </span>
</template>

<script>
export default {
  props: ["items", "label", "placeholder", "email", "apps"],
  data() {
    return {
      emails: [],
      allApps: null,
      search: null,
      errEmail: null,
      regexValidEmail: new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
    };
  },
  watch: {
    emails: function (v) {
      !this.errEmail ? this.$emit("update", v) : "";
    },
    errEmail: function (v) {
      v ? this.$emit("emailError", v) : "";
    },
    allApps: function (v) {
      this.$emit("update", v);
    },
  },
  methods: {
    validate(detail) {
      if (detail.length) {
        const email = detail[detail.length - 1];
        if (email.includes(",")) {
          const emailIndex = this.emails.indexOf(email);
          this.emails.splice(emailIndex, 1);
          const emailArr = email.split(",");
          emailArr.forEach((e) => {
            const testEmail = e.trim();

            if (this.regexValidEmail.test(testEmail)) {
              this.emails.push(testEmail);
            }
          });
        }
      }
      if (this.email) {
        let err = [];
        this.emails.forEach((e) => {
          !this.regexValidEmail.test(e) ? err.push("err") : err.push("noErr");
        });
        err.includes("err") ? (this.errEmail = true) : (this.errEmail = false);
        if (this.emails.length >= 20) {
          this.emails.length = 20;
        }
      }
    },

    handlePaste(event) {
      event.preventDefault(); // Prevent the default paste action

      const pastedText = event.clipboardData.getData("text");
      const delimiterRegex = /[,;\s]+/; // Delimiters: comma, semicolon, and space
      const pastedEmails = pastedText
        .split(delimiterRegex)
        .map((email) => email.trim());

      // Combine existing and new emails, filter out empty strings, and remove duplicates
      this.emails = [
        ...new Set([...this.emails, ...pastedEmails.filter((email) => email)]),
      ];
    },
    validateApps() {
      if (this.allApps.length >= 12) {
        this.allApps.length = 12;
      }
    },
    closeIt() {
      this.$refs.select.blur;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.error-tag-email span.v-chip {
  background-color: $error !important;
}
</style>
