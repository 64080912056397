var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.email ? _c('v-combobox', {
    attrs: {
      "multiple": "",
      "items": _vm.items,
      "label": _vm.label || 'Add Label Here',
      "placeholder": _vm.placeholder || '',
      "delimiters": [',', ';', ' '],
      "error": _vm.errEmail,
      "messages": _vm.errEmail ? 'Please enter valid email(s)' : null,
      "append-icon": "",
      "chips": "",
      "tags": "",
      "deletable-chips": "",
      "hint": "Paste emails using comma, semicolon, space, or newline as separators.",
      "small-chips": "",
      "filled": "",
      "counter": "20",
      "attach": ""
    },
    on: {
      "paste": _vm.handlePaste,
      "input": _vm.validate
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent,
          selected = _ref.selected;
        return [_c('v-chip', _vm._b({
          attrs: {
            "color": !_vm.regexValidEmail.test(item) ? 'error' : '',
            "input-value": selected,
            "small": ""
          }
        }, 'v-chip', attrs, false), [_c('span', {
          staticClass: "pr-2"
        }, [_vm._v(" " + _vm._s(item) + " ")]), _c('v-icon', {
          attrs: {
            "small": "",
            "color": !_vm.regexValidEmail.test(item) ? 'white' : 'dark-grey'
          },
          on: {
            "click": function click($event) {
              parent === null || parent === void 0 ? void 0 : parent.selectItem(item);
            }
          }
        }, [_vm._v(" cancel ")])], 1)];
      }
    }], null, false, 3748438245),
    model: {
      value: _vm.emails,
      callback: function callback($$v) {
        _vm.emails = $$v;
      },
      expression: "emails"
    }
  }) : _vm._e(), _vm.apps ? _c('v-select', {
    ref: "select",
    attrs: {
      "items": _vm.items,
      "menu-props": {
        maxHeight: '400',
        offsetY: true
      },
      "label": _vm.label || 'Add Label Here',
      "placeholder": _vm.placeholder || 'placeholder',
      "filled": "",
      "multiple": "",
      "autocomplete": "",
      "item-text": "name",
      "item-value": "id",
      "deletable-chips": "",
      "small-chips": "",
      "persistent-hint": "",
      "counter": "12"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "ml-5"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")]), _c('v-spacer'), data && data.item && data.item.platforms && data.item.platforms.includes('android') ? _c('PlatformIcon', {
          staticClass: "mr-4",
          attrs: {
            "size": "20",
            "android": "",
            "color": "primary"
          }
        }) : _vm._e(), data && data.item && data.item.platforms && data.item.platforms.includes('ios') ? _c('PlatformIcon', {
          attrs: {
            "size": "20",
            "apple": "",
            "color": "primary"
          }
        }) : _vm._e()];
      }
    }], null, false, 1187923384),
    model: {
      value: _vm.allApps,
      callback: function callback($$v) {
        _vm.allApps = $$v;
      },
      expression: "allApps"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }